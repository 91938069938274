<template>
    <div>
        <!--外层列表-->
        <page-list ref="packageInfo"
                   :package-list="packageList"
                   :search-to-props="searchToProps"
                   @search="search"
                   @clickItem="clickItem">
            <!--单个包-->
            <template v-slot="scope">
                <div>
                    <color-card :title="scope.item.name" :tips="'地区 · ' + scope.item.area"/>
                </div>
            </template>
        </page-list>
    </div>
</template>

<script>
import area from '@/util/area_new.js'
import PageList from "../components/pageList"
import ColorCard from "@/components/ColorCard"

export default {
    name: "index",
    components: {ColorCard, PageList},
    data() {
        return {
            packageList: [],
            // 搜索字段映射
            searchToProps: {
                area: {
                    label: '按 地 域',
                    type: 'selectString',
                    value: undefined,
                    options: []
                },
                name: {
                    label: '按 名 称',
                    type: 'input'
                }
            },
            list: [
                {
                    name: '123'
                }
            ]
        }
    },
    created() {
        // 更新地域
        let options = [
            {label: '全部', value: undefined}
        ]
        area.forEach(item => {
            options.push({
                label: item.text,
                value: item.text
            })
        })
        this.searchToProps.area.options = options
    },
    methods: {
        // 搜索数据
        search(data) {
            console.log(data)
            // 向后端发送请求数据
            this.$api.post('/university/index/getAllUniversity', data).then(res => {
                this.packageList = res.data.records
                this.$refs.packageInfo.updateTotal(res.data.total)
            }).catch(err => {
                console.log('院校数据获取失败', err)
            })
        },
        // 点击数据
        clickItem(item) {
            this.$router.push('/university/detail/' + item.id)
        }
    }
}
</script>