const area = [{"value": 0, "text": "北京"}, {"value": 1, "text": "天津"}, {"value": 2, "text": "河北"}, {
    "value": 3,
    "text": "山西"
}, {"value": 4, "text": "内蒙古"}, {"value": 5, "text": "辽宁"}, {"value": 6, "text": "吉林"}, {
    "value": 7,
    "text": "黑龙江"
}, {"value": 8, "text": "上海"}, {"value": 9, "text": "江苏"}, {"value": 10, "text": "浙江"}, {
    "value": 11,
    "text": "安徽"
}, {"value": 12, "text": "福建"}, {"value": 13, "text": "江西"}, {"value": 14, "text": "山东"}, {
    "value": 15,
    "text": "河南"
}, {"value": 16, "text": "湖北"}, {"value": 17, "text": "湖南"}, {"value": 18, "text": "广东"}, {
    "value": 19,
    "text": "广西"
}, {"value": 20, "text": "海南"}, {"value": 21, "text": "重庆"}, {"value": 22, "text": "四川"}, {
    "value": 23,
    "text": "贵州"
}, {"value": 24, "text": "云南"}, {"value": 25, "text": "西藏"}, {"value": 26, "text": "陕西"}, {
    "value": 27,
    "text": "甘肃"
}, {"value": 28, "text": "青海"}, {"value": 29, "text": "宁夏"}, {"value": 30, "text": "新疆"}]
export default area